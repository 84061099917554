(function () {
  'use strict';

  angular
    .module('neo.public.calendar')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('public.calendar', {
        url: '/calendar',
        templateUrl: 'public/calendar/calendar.tpl.html',
        controller: 'CalendarCtrl',
        controllerAs: 'vm',
        resolve: {
          championships: function (ChampionshipsPublic) {
            return ChampionshipsPublic.query().$promise;
          },
          phases: function (PhasesPublic) {
            return PhasesPublic.query().$promise;
          },
          sports: function (Sports) {
            return Sports.query().$promise;
          },
          categories: function (Categories) {
            return Categories.query().$promise;
          },
          genders: function (Genders) {
            return Genders.query().$promise;
          }
        }
      });
  }
}());
